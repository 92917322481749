
import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  Col,
  Collapse,
  CollapseItem,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  Loading,
  Message,
  MessageBox,
  Option,
  Radio,
  RadioGroup,
  Select,
  Tooltip
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import langDE from 'element-ui/lib/locale/lang/de'
import langEN from 'element-ui/lib/locale/lang/en'
import langFR from 'element-ui/lib/locale/lang/fr'
import ElementLocale from 'element-ui/lib/locale'

export const elementLocale = ElementLocale

export const init = (vueInstance) => {
vueInstance.use(Alert)
vueInstance.use(Button)
vueInstance.use(ButtonGroup)
vueInstance.use(Checkbox)
vueInstance.use(Col)
vueInstance.use(Collapse)
vueInstance.use(CollapseItem)
vueInstance.use(Dialog)
vueInstance.use(Dropdown)
vueInstance.use(DropdownItem)
vueInstance.use(DropdownMenu)
vueInstance.use(Form)
vueInstance.use(FormItem)
vueInstance.use(Input)
vueInstance.use(Loading)
vueInstance.use(Option)
vueInstance.use(Radio)
vueInstance.use(RadioGroup)
vueInstance.use(Select)
vueInstance.use(Tooltip)
vueInstance.prototype.$msgbox = MessageBox
vueInstance.prototype.$alert = MessageBox.alert
vueInstance.prototype.$confirm = MessageBox.confirm
vueInstance.prototype.$prompt = MessageBox.prompt
vueInstance.prototype.$message = Message
}

export const getElementLocaleMap = (aLang = '') => {
  const langCode = aLang.substring(0, 2).toLowerCase()
  const localeMap = {
    'de': langDE,
    'en': langEN,
    'fr': langFR
  }
  if (!localeMap[langCode]) {
    return localeMap['de']
  }
  return localeMap[langCode]
}
