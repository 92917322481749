const FontFaceObserver = require('fontfaceobserver')
const fontA = new FontFaceObserver('Assistant')
const fontB = new FontFaceObserver('Roboto Slab')

Promise.all([fontA.load(), fontB.load()]).then(
  () => {
    document.documentElement.className += ' fonts-loaded'
  },
  () => {
    console.log('Font is not available after waiting 5 seconds') // eslint-disable-line
  }
)
