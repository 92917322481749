/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'
import DialogMessage from './dialogMessages'

export default class Dialogs extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'dialogs'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      author: this.attr(''),
      body: this.attr(''),
      absentDate: this.attr(null),
      draft: this.attr(false),
      read: this.attr(false),
      role: this.attr(null),
      subject: this.attr(''),
      threadCount: this.attr(''),
      timestamp: this.attr(null),
      outgoing: this.attr(null),
      messages: this.hasMany(DialogMessage, 'rootId'),
      counselorDeleted: this.attr(false),
      emailCounselorRemoved: this.attr(false)
    }
  }
}
