import Vue from 'vue'
import { format, parseISO, formatDistanceToNow } from 'date-fns'
import deLocale from 'date-fns/locale/de'

Vue.filter('simpledate', dateString => {
  const date = parseISO(dateString)
  const curYear = format(new Date(), 'yyyy')
  const tYear = format(date, 'yyyy')
  return format(date, `d. MMMM${curYear !== tYear ? ' yyyy' : ''}`, {
    locale: deLocale
  })
})

Vue.filter('datetime', timestamp => {
  return format(parseISO(timestamp), 'yyyy-MM-ddTHH:mm:ss.SSSZ', { locale: deLocale })
})

Vue.filter('time', timestamp => {
  return format(parseISO(timestamp), 'HH:mm', { locale: deLocale })
})

Vue.filter('distance', (timestamp) => formatDistanceToNow(parseISO(timestamp), { locale: deLocale, addSuffix: true }))
