import api from 'api'

export default {
  getters: {
    announcement: (state, getters) => getters['entities/announcement/query']().first()
  },
  actions: {
    loadAnnouncement: async ({ dispatch, commit }) => {
      commit('setPageDataLoading', true)
      const result = await api.call('announcement', false, false)
      dispatch('entities/announcement/deleteAll')
      dispatch('entities/announcement/create', { data: result.data })
      commit('setPageDataLoading', false)
    },
    storeAnnouncement: ({ dispatch }, val) => {
      dispatch('entities/announcement/create', { data: val })
    }
  }
}
