export default {
  getters: {
    dialogs: (state, getters) =>
      getters['entities/dialogs/query']()
        .with('messages')
        .orderBy('timestamp', 'desc')
        .get(),

    currentDialog: (state, getters, rootState) =>
      getters['entities/dialogs/query']()
        .with('messages', (query) => {
          query.orderBy('timestamp', 'desc')
        })
        .where('id', rootState.route.params.dialogId)
        .first()
  },
  actions: {
    storeDialogAsRead({ dispatch }, id) {
      dispatch('entities/dialogs/update', {
        where: id,
        data: { read: true }
      })
    }
  }
}
