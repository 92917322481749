import api from 'api'

export default {
  getters: {
    dialogMessages: (state, getters, rootState) =>
      getters['entities/dialogMessages/query']()
        .where('rootId', rootState.route.params.dialogId)
        .get()
  },
  actions: {
    markAsRead: async function({ dispatch }, ids) {
      await api.call('markAsRead', { rootId: ids.rootId, id: ids.id }, { read: 1 })
      dispatch('entities/dialogMessages/update', {
        where: ids.id,
        data: {
          read: true
        }
      })
    },

    removeDialogItem: function({ dispatch }, id) {
      dispatch('entities/dialogMessages/delete', id)
    },

    clearDialog({ dispatch }) {
      dispatch('entities/dialogMessages/deleteAll')
    }
  }
}
