import Announcement from './models/announcement'
import announcement from './modules/announcement'
import CounselingCenter from './models/counseling-center'
import counselingCenter from './modules/counseling-center'
import Dialogs from './models/dialogs'
import dialogs from './modules/dialogs'
import DialogMessage from './models/dialogMessages'
import dialogMessage from './modules/dialogMessages'
import ChatDialogs from './models/chatDialogs'
import chatDialogs from './modules/chatDialogs'
import ChatDialogMessage from './models/chatDialogMessages'
import chatDialogMessage from './modules/chatDialogMessages'
import User from './models/user'
import user from './modules/user'
import Faq from './models/faq'
import faq from './modules/faq'
import { Database, install } from '@vuex-orm/core'

const database = new Database()

database.register(Announcement, {})
database.register(CounselingCenter, {})
database.register(Dialogs, {})
database.register(DialogMessage, {})
database.register(ChatDialogs, {})
database.register(ChatDialogMessage, {})
database.register(User, {})
database.register(Faq, {})

export const ORMDatabase = install(database)
export const ORMModules = {
  announcement,
  counselingCenter,
  dialogs,
  dialogMessage,
  chatDialogs,
  chatDialogMessage,
  user,
  faq
}
