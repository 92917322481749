import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import frLocale from 'date-fns/locale/fr'
const dateLocales = {
  de: deLocale,
  en: enLocale,
  fr: frLocale
}

export const getDateLocale = (aLang) => {
  const lang = aLang.toLowerCase().substring(0, 2)
  if (!dateLocales[lang]) {
    return dateLocales['de']
  }
  return dateLocales[lang]
}
