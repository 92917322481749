// Main JS File
import 'style/style.scss'
import 'core-js/stable'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { sync } from 'vuex-router-sync'
import api from 'api'
import router from './router'
import store from './store'
import './partial/webfonts'
import './partial/detect-browser'
import App from './App'
import './registerServiceWorker'
// Vue Plugins
import VueCookie from 'vue-cookie'
import VueLogger from 'vue-logger'
// import VueTextareaAutosize from 'vue-textarea-autosize'

/**
 * Filters
 */
import './shared/filters'


import { init, getElementLocaleMap, elementLocale } from './plugins/element-ui'
init(Vue)
// OfflinePluginRuntime.install()

// keep vue-router and vuex store in sync
sync(store, router)

// Vue.use(VueTextareaAutosize)
Vue.use(VueI18n)
Vue.use(VueCookie)
Vue.use(VueLogger, {
  prefix: () => new Date(),
  dev: process.env.NODE_ENV === 'development',
  shortname: true,
  levels: ['log', 'warn', 'debug', 'error', 'dir']
})

/**
 * Lang File
 */
let languages = store.getters.languages
let loadLangCode = languages[0]
const urlParams = new URLSearchParams(window.location.search)
const hashQuery = window.location.hash.slice(window.location.hash.indexOf('?') + 1) || ''
const hashParameters = hashQuery.split('&').reduce(function (res, item) {
  const parts = item.split('=')
  res[parts[0]] = parts[1]
  return res
}, {})
const queryLang = urlParams.get('lang') || hashParameters['lang']
if (queryLang) {
  const langCode = languages.filter((aLanguage) => aLanguage.toLowerCase().includes(queryLang.toLowerCase())).pop()
  loadLangCode = langCode || loadLangCode
} else {
  const navLang = window.navigator && window.navigator.language ? window.navigator.language.toLowerCase() : ''
  const langCode = languages.filter((aLanguage) => aLanguage.toLowerCase().includes(navLang)).pop()
  loadLangCode = langCode || loadLangCode
}
store.commit('setLanguage', loadLangCode)
store.commit('setLandingTexts', { locale: loadLangCode, landingTexts: window.landingTexts })
let loadLangFile = loadLangCode + '.json'

// if (VueCookie.get("lang") && languages[VueCookie.get("lang")]) {
//   loadLangFile = languages[VueCookie.get("lang")];
//   loadLangCode = VueCookie.get("lang");
// }

// Vue App
/* eslint-disable no-new */
api
  .getLang(`${loadLangFile}?t=${process.env.VUE_APP_BUILD_TIMESTAMP}`)
  .then(response => {
    const messages = {}
    messages[loadLangCode] = { ...getElementLocaleMap(loadLangCode), ...response.data }

    // Create VueI18n instance with options
    const i18n = new VueI18n({
      locale: loadLangCode, // set locale
      messages // set locale messages
    })
    elementLocale.i18n((key, value) => i18n.t(key, value))

    new Vue({
      // eslint-disable-line
      el: '#app',
      router,
      store,
      i18n,
      render: h => h(App)
    })
  })
  .catch(error => {
    console.error('Couldn\'t load language', error) // eslint-disable-line
    Vue.prototype.$cookie.delete('token')
    Vue.prototype.$cookie.delete('messageRead')
    api.unsetAuth()
  })
