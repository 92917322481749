import { getDateLocale } from '../shared/dateLocale'
export const offcanvasOpen = (state) => state.offcanvasOpen
export const dialogOpen = (state) => state.dialogOpen
export const chatDialogOpen = (state) => state.chatDialogOpen
export const confirmOpen = (state) => state.confirmOpen
export const messageEditorOpen = (state) => state.messageEditorOpen
export const dialogLoading = (state) => state.dialogLoading
export const dialogsLoading = (state) => state.dialogsLoading
export const pageDataLoading = (state) => state.pageDataLoading
export const sentMessages = (state) => state.sentMessages
export const editMessage = (state) => state.editMessage
export const initialEditMessage = (state) => state.initialEditMessage
export const currentDialogId = (state) => state.route.params.dialogId
export const currentRouteName = (state) => state.route.name
export const language = state => state.language
export const chatIsDisabled = state => state.chatIsDisabled
export const languages = state => state.settings.languages
export const languageCounselingCenterMap = state => state.settings.languageCounselingCenterMap
export const idleTimeSeconds = (state) => state.idleTimeSeconds
export const landingTexts = (state) => state.landingTexts[state.language]
export const hasUnsavedChanges = (state) => {
  const original = state.initialEditMessage
  const changed = state.editMessage
  return original && (original.subject !== changed.subject || original.body !== changed.body)
}
export const interruptedRoute = (state) => state.interruptedRoute

export const theme = () => {
  const regex = new RegExp('[\\?&]r=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null ? 'default' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const chatIsOpen = (state) => state.currentChatEvent !== null
export const typingState = (state) =>
  state.typingState && state.currentUserChat !== null && state.currentUserChat.status === 'active'
export const currentChatEvent = (state) => state.currentChatEvent
export const currentUserChat = (state, getters) => {
  const currentUserChat = state.currentUserChat
  if (currentUserChat === null) {
    return null
  }
  const status = currentUserChat.status
  if (!['pending', 'active'].includes(status) && !getters.showClosedMessage) {
    return null
  }
  return currentUserChat
}
export const chatWasCanceled = (state) => {
  return (
    state.currentUserChat &&
    (state.currentUserChat.status === 'canceled' || state.currentUserChat.status === 'aborted') &&
    !state.chatIsOpen
  )
}
export const showClosedMessage = (state, getters) => {
  const currentChat = state.currentUserChat
  return (
    currentChat &&
    ['closed', 'finished'].includes(currentChat.status) &&
    currentChat.closedInfoRead === false &&
    currentChat.closedBy !== getters.userInfo.id
  )
}
export const hasActiveChat = (state, getters) => getters.currentUserChat !== null

export const needsChatInteraction = (state) => {
  return (state.route.name !== 'liveChat' || state.isPageHidden) && state.newChatMessage
}

export const newChatMessage = (state) => {
  return state.newChatMessage
}

export const needsMailInteraction = (state, getters) => {
  return (
    (state.route.path.indexOf('/kjt/dialogs') === -1 || state.isPageHidden) &&
    getters.userInfo &&
    getters.userInfo.unreadCount > 0
  )
}

export const counselingCenterId = (state) => state.counselingCenterId
export const dateLocale = (state) => {
  return getDateLocale(state.language)
}
