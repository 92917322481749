import api from 'api'
// import axios from 'axios'

import CounselingCenter from '../models/counseling-center'

/** @type {import('vuex').Module<any, import('../models/counseling-center').default} */
const counselingCenterModule = {
  getters: {
    counselingCenters: function () {
      return CounselingCenter.query().orderBy('name', 'asc').get()
    }
  },
  actions: {
    loadCounselingCenters: async function ({ commit }) {
      commit('setPageDataLoading', true)

      try {
        const counselingCenters = await api.call('getCounselingCenters')
        CounselingCenter.deleteAll()
        CounselingCenter.create({ data: counselingCenters.data.results })
      } catch (error) {
        return error
      } finally {
        commit('setPageDataLoading', false)
      }

      // return api.call('getCounselingCenters')
    },
    createCounselingCenter: async function (context, counselingCenter) {
      // const result = await api.call('createCounselingCenter', undefined, counselingCenter)
      // return CounselingCenter.create({ data: result.data.results })
      await api.call('createCounselingCenter', undefined, counselingCenter)
      return CounselingCenter.create({ data: counselingCenter })
    },
    updateCounselingCenter: async function (context, { id, counselingCenter }) {
      await api.call('updateCounselingCenter', id, counselingCenter)
      return CounselingCenter.update({ data: counselingCenter })
    },
    deleteCounselingCenter: async function (context, counselingCenter) {
      await api.call('deleteCounselingCenter', counselingCenter.id)
      return CounselingCenter.delete({ where: counselingCenter.id })
    }
  }
}

export default counselingCenterModule
